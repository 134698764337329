
import "./newsletter-window-styles.css"
import SignUpFormExtended from "./sing-up-form-extended";

export default function NewsletterWindow({newsLetterActive, NewsLetterToggle}) {


    return (
      <div className={`newsletter ${newsLetterActive? "open" : ""}`}>
      
        <SignUpFormExtended NewsLetterToggle={NewsLetterToggle} newsLetterActive={newsLetterActive} />

      </div>
    );
}