
import './App.css';
import WaveBackground from './components/wave-background';
import NavBar from './components/nav-bar';
import Main from './components/main';
import Footer from './components/footer';
import MainMenuWindow from './components/main-menu-window';
import React from 'react';
import People from './components/people';
import About from './components/about';
import {useLocation , BrowserRouter, Routes, Route, Router } from 'react-router-dom';
import Presentation from './components/presentation';
import NewsletterPage from './components/newsletter-window';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import NewsletterWindow from './components/newsletter-window';



function App() {

  

  let [mainMenuActive, setMainMenuActive] = React.useState(false)
  let [newsLetterActive, setNewsLetterActive] = React.useState(false)

 function MainMenuToggle() {
  mainMenuActive? setMainMenuActive(false) : setMainMenuActive(true)
  }


  function NewsLetterToggle() {
   newsLetterActive? setNewsLetterActive(false) : setNewsLetterActive(true)
  }

  const [t, setT] = React.useState(0);
  const [f, setF] = React.useState(0.0005);
  const [a, setA] = React.useState(6);



  return (
    <BrowserRouter>
      <div className="App">
        <WaveBackground t={t} f={f} a={a} />
        <NavBar
          MainMenuToggle={MainMenuToggle}
          mainMenuActive={mainMenuActive}
          NewsLetterToggle={NewsLetterToggle}
          newsLetterActive ={newsLetterActive}
        />
      
        <NewsletterWindow newsLetterActive={newsLetterActive} NewsLetterToggle={NewsLetterToggle} />

        <MainMenuWindow
          mainMenuActive={mainMenuActive}
        />

        <div>
          {mainMenuActive || newsLetterActive ? (<div className="blank"></div>
           
          ) : (
            <div className="main-screen">
            <Routes>
              <Route exact path="/" element={<Presentation />} />
              <Route path="/people" element={<People />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
            
          )}
          {!mainMenuActive && !newsLetterActive && <Footer mainMenuActive={mainMenuActive} />}

          
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
