import React from "react"
import "./nav-bar-styles.css"
import SandwichMenu from "./sandwich-menu"
import NewsletterButton from "./newsletter-button"


export default function NavBar({MainMenuToggle, mainMenuActive, NewsLetterToggle, newsLetterActive}) {

    
    let [scrollPos, setScrollPos] = React.useState(window.pageYOffset)
    let [hideNavBar, setHideNavBar] = React.useState(false)
    let [navBarBackground, setNavbarBackground] = React.useState(false)


    React.useEffect(()=> {

        
        window.onscroll = () => {

            
            setScrollPos((prevScrollPos) => {
            
                if (prevScrollPos < window.pageYOffset ) {
                    
                    setHideNavBar(true)
                
                } else { 
                    
                    setHideNavBar(false)
                
                };


                
                if (window.pageYOffset > 100) {
                    setNavbarBackground(true)
                  
                
                } else {setNavbarBackground(false)
                    }

                return window.pageYOffset;
            });

        };


    }, []);


    var [navBarMode, setNavBarMode] = React.useState("light")





    
    return(
        

    
    <div className={hideNavBar || newsLetterActive ? "nav-bar-hidden" : "nav-bar" }>

        <div className="nav-bar-elements">

            <div className="nav-bar-left">

            <div className="logo-wrap">
                <img className="realbeat-logo" src={"./assets/RealBeat_Logo_03_Small.png"}></img>
            </div>

            </div>

            <div className="nav-bar-right">

                

                

                <div className="nav-bar-menu-wrap">

                    <NewsletterButton MainMenuToggle={MainMenuToggle} mainMenuActive={mainMenuActive} NewsLetterToggle={NewsLetterToggle} />

                    <SandwichMenu MainMenuToggle={MainMenuToggle} mainMenuActive={mainMenuActive}/>

                </div>


            </div>


        </div>
        
    </div>
        
    )
}