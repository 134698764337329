import "./people-styles.css"
import { peopleData } from "./peopleData";

export default function People() {
  function PeopleBox({ photo, name, title, bio, email, number }) {
    return (
      <div className="people-box">
        <img className="people-photo" src={photo}></img>

        <div className="people-info">
          <div className="people-name-wrap">
            <h3 className="people-name">{name}</h3>
            <h6 className="people-title">{title}</h6>
          </div>

          <p className="people-bio">{bio}</p>

          
        </div>
      </div>
    );
  }

  return (
    <div className="people-wrap">
      <PeopleBox
        photo={peopleData[0].photo}
        name={peopleData[0].name}
        title={peopleData[0].title}
        bio={peopleData[0].bio}
      />

      <PeopleBox
        photo={peopleData[1].photo}
        name={peopleData[1].name}
        title={peopleData[1].title}
        bio={peopleData[1].bio}
      />
    </div>
  );
}