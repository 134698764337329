import React from "react";
import "./sandwich-menu-styles.css"


export default function SandwichMenu ({MainMenuToggle, mainMenuActive}) {

    return (
        <div className="sandwich-menu" onClick={MainMenuToggle}>
            <div className="bar" id={mainMenuActive?"upper_2":"upper"}></div>  
            <div className="bar" id={mainMenuActive?"middle_2":"middle"}></div>
            <div className="bar" id={mainMenuActive?"lower_2":"lower"}></div>
        </div>
    )
}