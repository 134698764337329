import React from 'react';
import "./sign-up-form-extended-styles.css"
import MailchimpSubscribe from 'react-mailchimp-subscribe';






  function CustomForm({status, onValidated, newsLetterActive}) {

    const [email, setEmail] = React.useState('');
    const [formMessage, setFormMessage] = React.useState("")
    const [formMessageStyle, setFormMessageStyle] = React.useState("DEFAULT")
    const [formImg, setFormImg] = React.useState("./assets/Newsletter_Icon_2_Black.png")
    const [inputFieldVisible, setInputFieldVisible] = React.useState("VISIBLE")

    React.useEffect(()=>{

      setFormMessage("")
      setFormMessageStyle("DEFAULT")
      setFormImg("./assets/Newsletter_Icon_2_Black.png")
      setInputFieldVisible("VISIBLE")
      
    }, [newsLetterActive])

  

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    }

 


    function HandleSubmit(e) {
      e.preventDefault();
      email && email.indexOf("@") > -1 &&
      onValidated({
          EMAIL: email,
  
      });
  }

  React.useEffect(()=>{
    if(status==="sending") {
      setFormImg("./assets/sending_icon.png")
      setFormMessage("Sending...")
      setFormMessageStyle("SENDING")
      setInputFieldVisible("HIDDEN")

      
    }
    else if(status==="error") {
      setFormMessage("Opps, something went wrong! Please Try again.")
      setFormImg("./assets/Newsletter_Icon_Error.png")
      setFormMessageStyle("ERROR")
      setInputFieldVisible("VISIBLE")
      
    
    }
    else if (status==="success") {
      setFormMessage("Subscribed!")
      setFormImg("./assets/Newsletter_Icon_OK_3.png")
      setFormMessageStyle("SUCCESS")
      setInputFieldVisible("HIDDEN")
    }

  }, [status])


    return (
      <form className="extended-form" onSubmit={(e) => HandleSubmit(e)}>
        
        <div className="info-field">
          <div className="form-icon-wrap">
            <img
              src={formImg}
              className="form-icon"
              id = {formImg==="./assets/sending_icon.png"?"sending-img":""}
            ></img>
          </div>

          <div className='form-message' id={formMessageStyle}>
            {formMessage}
          </div>

          <div className="form-text-wrap" id={inputFieldVisible}>
            <label id="label-default">Join our Newsletter!</label>
            <p className="form-text">
              For recieving the latest news about RealBeat.
            </p>
          </div>
        </div>
        
        <div className="input-field-wrap" id={inputFieldVisible}>
          <input
            className='email-field'
            label="email"
            onChange={handleEmailChange}
            type="text"
            value={email}
            placeholder="Insert Your Email"
            isRequired
          ></input>
       
          <input className='join-button' label="Join" type="submit" formValues={[email]}></input>
       
        </div>

       
      </form>
    );
    }
  



  export default function SignUpFormExtended({NewsLetterToggle, newsLetterActive}) {


    const postURL = "https://realbeatrecords.us21.list-manage.com/subscribe/post?u=5aa272709fa147b74cda6f484&id=042a452c50";

    return (
      <div className="extended-form-container">
        <div className='close-icon-wrap' src="./assets/Newsletter_Icon_OK_2.png" onClick={NewsLetterToggle}>
          <img className='close-icon' src="./assets/close_icon.png"></img>
        </div>
        <MailchimpSubscribe
          url={postURL}
          render={({ subscribe, status }) => (
            <CustomForm
              newsLetterActive = {newsLetterActive}
              status={status}
              onValidated={(formData) => subscribe(formData)}
            />
          )}
        />
      </div>
    );
  }






