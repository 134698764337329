import React from "react"
import "./contact-email-styles.css"

export default function ContactEmail({address}) {


    let [message, setMessage] = React.useState("Copy to cliboard?")
    let [showMessage, setShowMessage] = React.useState(false)

    function CopyToCliboard() {
        setMessage("Copy To Clipboard?")
        setShowMessage(true)
    }

    function Copied() {
        navigator.clipboard.writeText(address)
        setMessage("Copied (:")
        setShowMessage(true)
    }

    function MouseOut() {
        setShowMessage(false)
    }

    return (

        <div className="email">
            <p className="copy-message" style={showMessage ? {opacity:"1", transiton:"0.1s"} : {opacity:"0", transiton:"0.1s"}}> {message} </p>
            <h2 className="email-text" onMouseEnter={CopyToCliboard} onMouseOut={MouseOut} onClick = {Copied} >realbeat.ltd@gmail.com</h2>
        </div>
      
    )
    
    
    
    }

  