
import React from "react"
import "./social-media-styles.css"

export default function SocialMedia() {

    return (

        
<div className="social-media-wrap">
<a target="_blank" href="https://soundcloud.com/deck_lamano"><img className="social-media-icon"  src="./assets/Soundcloud_Icon.png"></img></a>
<a target="_blank" href="https://www.instagram.com/deck.lamano/"><img className="social-media-icon"  src="./assets/Instagram_Icon.png"></img></a>
<a target="_blank" href="https://www.facebook.com/deck.lamanoderecha/"><img className="social-media-icon"  src="./assets/Facebook_Icon.png"></img></a>
<a target="_blank" href="https://wa.me/447840595482"><img className="social-media-icon" src="./assets/Whatsapp_Icon.png"></img></a>
</div>
    )


    
    
}










