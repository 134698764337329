import React from "react";
import "./footer-styles.css";
import SignUpformEmbeded from "./sign-up-form-embeded";
import ContactEmail from "./contact-email";
import SocialMedia from "./social-media";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-elements-wrap">
       
        <div className="footer-section" id="logo">
          <img className="full-logo"src="./assets/RealBeat_Full_Logo.png"></img>
        </div>
     

      
      <div  className="footer-section" id="ML">
        <SignUpformEmbeded/>
      </div>

    


      <div  className="footer-section" id="email">
        <ContactEmail address={"contact@realbeatrecords.com"}/>
      </div>


      

      <div  className="footer-section" id="social-media">
        <SocialMedia />
      </div>


  

      <div  className="footer-section" id="copyright">
        <p className="copyright-text">©Copyright 2023. RealBeat Records is a Limited company registered in England and operates in Italy, Usa & Latin America. Website built by Joscoyne. </p>
      </div>

      </div>

     

      



    

      
     
    </div>
  );
}
