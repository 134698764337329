
import "./newsletter-button-styles.css"
import { BrowserRouter as Router, Route, Link, BrowserRouter, Routes } from 'react-router-dom';
import styled from "styled-components";
import React from "react";


export default function NewsletterButton ({mainMenuActive, NewsLetterToggle}) {

    let [buttonStyle, setButtonStyle] = React.useState("contact-button")

    React.useEffect (()=> {  if (mainMenuActive) {setButtonStyle("contact-button-frozen")} else {setButtonStyle("contact-button")}})


    return (
       <button className={buttonStyle} onClick={NewsLetterToggle}>Join Newsletter</button>
    )
        
    
}