import React from 'react';
import "./sign-up-form-embeded-styles.css"
import MailchimpSubscribe from 'react-mailchimp-subscribe';






  function CustomFormEmbeded({status, onValidated, newsLetterActive}) {

    const [email, setEmail] = React.useState('');
    const [formMessage, setFormMessage] = React.useState("Join our Newsletter!")
    const [formMessageStyle, setFormMessageStyle] = React.useState("DEFAULT-2")
    const [inputFieldVisible, setInputFieldVisible] = React.useState("OPEN")

 

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    }

 


    function HandleSubmit(e) {
      e.preventDefault();
      email && email.indexOf("@") > -1 &&
      onValidated({
          EMAIL: email,
  
      });
  }

  React.useEffect(()=>{
    if(status==="sending") {
      setFormMessageStyle("DEFAULT-2")
      setFormMessage("Sending at nearly the speed of light...")
      setInputFieldVisible("COLLAPSED")
      
    }
    else if(status==="error") {
      setFormMessage("Opps, something went wrong! Please Try again.")
      setFormMessageStyle("ERROR-2")
      setInputFieldVisible("OPEN")
     
    
    }
    else if (status==="success") {
      setFormMessage("✔ Subscribed! Thank you!")
      setFormMessageStyle("SUCCESS-2")
      setInputFieldVisible("COLLAPSED")

     
    }

  }, [status])


    return (
      <form className="embeded-form" onSubmit={(e) => HandleSubmit(e)}>
        
        <div className="form-elements-wrap">
          <label className="newsletter-label" id={formMessageStyle}>{formMessage}</label>

          <div className="input-wrap" id={inputFieldVisible}>
            
            <input
              type="email"
              placeholder="Insert your email"
              value={email}
              name="EMAIL"
              className="required-email"
              id="mce-EMAIL"
              onChange={handleEmailChange}
              required
            />

            <input
              type="submit"
              value="Join"
              name="subscribe"
            
              className="subscribe-button"
            />
          </div>
        </div>

       
      </form>
    );
    }
  














export default function SignUpformEmbeded() {

  
  const postURL = "https://realbeatrecords.us21.list-manage.com/subscribe/post?u=5aa272709fa147b74cda6f484&id=042a452c50";

 
  return (
    <div className="embeded-form-wrap">
    <MailchimpSubscribe
      url={postURL}
      render={({ subscribe, status }) => (
        <CustomFormEmbeded
          status={status}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  </div>
  );
}