import ContactEmail from "./contact-email";
import "./main-menu-window-styles.css";
import SocialMedia from "./social-media";
import { BrowserRouter as Router, Route, Link, BrowserRouter, Routes } from 'react-router-dom';


export default function MainMenuWindow({ mainMenuActive, MainMenuToggle }) {
  return (
    <div className={`main-menu-window ${mainMenuActive ? "open" : ""}`}>
      <div className="main-menu-window-elements-wrap">
        <div className="main-menu-window-section" id="menu-links">
          <a className="main-menu-link">
            <Link to="/" onClick={() => MainMenuToggle()}>
              Home
            </Link>
          </a>

          <a className="main-menu-link">
            <Link to="/people" onClick={() => MainMenuToggle()}>
              People
            </Link>
          </a>

          <a className="main-menu-link">
            <Link to="/about" onClick={() => MainMenuToggle()}>
              About
            </Link>
          </a>
        </div>

        

        <div className="main-menu-footer">


        

          <div className="main-menu-window-section" id="main-menu-window-email">
            <ContactEmail />
          </div>

          <div className="main-menu-window-section" id="social-media">
            <SocialMedia />
          </div>
        </div>
      </div>
    </div>
  );
}
