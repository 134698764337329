

export const peopleData = [
  {
    name: "Omar Taveras",
    title: "Director",
    photo: "./assets/Omar_Taveras_Photo.jpg",
    bio: "Omar Taveras is an Italo-Dominican engineer, sound designer and producer based in the UK and he is best known by his stage name “Deck Lamano”.Omar has been involved in the hip-hop scene since the early 2000s and has collaborated and performed with several artists around the world. Always passionate about all things music and production, he is deeply dedicated to his craft and always strives for going above and beyond. Currently a dBs Institute alumni, he founded RealBeat with the purpose of offering a new paradigm to the music industry, and creating a framework for talented artists where they can thrive both creatively and financially.",
    contact: "",
  },
  {
    name: "Alfonso Garcia",
    photo: "./assets/Alfonso_Garcia_Photo.jpg",
    title: "Co-Director",
    bio: "Alfonso Garcia is a Spanish saxophonist based in Plymouth, UK. A seasoned performer, he has played with multiple quartets and bands, including recording an album with Alex Garcia. Alfonso has studied in the music conservatoire and his influences include classical, jazz, latin, rock as well as the sounds of his native region, Andalusia. A charming, flaring and vivacious player, he is currently a member of the Sorbas Wind Band.He joined RealBeat for the purpose of promoting its model in Spain, as well as taking care of the general business developement and administration. ",
    contact: "",
  },
];
