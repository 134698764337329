import "./about-styles.css"


export default function About() {


    return (
      <div className="about">
        <div className="about-logo-wrap">
          <img className="about-logo" src="./assets/RealBeat_Full_Logo.png"></img>
        </div>

        <div className="about-text-wrap">
          <p className="about-text">
            RealBeat is a start-up aiming to emancipate both musicians and music
            fans by making them business partners. Imagine a completely
            decentralised music industry where fans are not simply supporters or
            patreons , but active investors in the art and work of the artists
            they choose to put their money on, and with potential long term
            gains that benefits both. <br></br> One different way to see it is “musicians
            or bands as start-ups”. The role of real beat is to create a
            networking system and infrastructure to make this possible and
            intermediating between artist and potential investors interested in
            adhering in this new model.
          </p>
        </div>
      </div>
    );
}