import "./presentation-styles.css";
export default function Presentation() {
  return (
    <div className="presentation">
      <div className="section" id="first">
        <div className="heading-wrap">
          <h1 id="slogan">
            Lets make this happen. <br></br>
            Together. <br></br>
          </h1>
        </div>

        <div className="paragraph-wrap">
          <p>
            RealBeat is a start-up aiming to emancipate both musicians and music
            fans by making them partners.
          </p>
          <p>
            Imagine a completely decentralised music industry where fans are not
            simply listeners or patreons , but active investors in the art and
            work of the artists they choose to support.
          </p>
        </div>
      </div>

      <div className="section" id="second">
        <div className="presentation-icon-wrap">
          <img
            className="presentation-svg"
            src="./assets/Presentation_Icon_02.png"
          ></img>
        </div>
        <div className="heading-wrap">
          <h1>
            A symbiotic Model. <br></br>
          </h1>
        </div>

        <div className="paragraph-wrap">
          <p>
            A self regulating system were true talent prevails: One big
            fan-investor can change the course of the career of an emerging
            artist, many small investors can further develop the career of
            rising stars, while also benefiting financially from their success!
          </p>
        </div>
      </div>

      <div className="section" id="third">
        <div className="presentation-icon-wrap">
          <img
            className="presentation-svg"
            src="./assets/Presentation_Icon_03.png"
          ></img>
        </div>
        <div className="heading-wrap">
          <h1>Musicians as Startups.</h1>
        </div>

        <div className="paragraph-wrap">
          <p>
            Artists will always own at least 50% of their work, the rest will be
            owned by fan-shareholders. Everyone will be part of the growth
            journey to success, whatever success means simply giving artists the
            means to continue making music, or becoming award winning global
            icons.
          </p>
        </div>
      </div>

      <div className="section" id="fourth">
        <div className="presentation-icon-wrap">
          <img
            className="presentation-svg"
            src="./assets/Presentation_Icon_04.png"
          ></img>
        </div>
        <div className="heading-wrap">
          <h1>Does it sound exciting to you?</h1>
          <p>
            The mission of RealBeat is to create a networking system and
            infrastructure to make this possible and intermediating between
            artist and potential fan-investors interested in adhering in this
            new model.
          </p>
        </div>

        <div className="paragraph-wrap">
          <p>
            Weather you are a musician or a music fan just <b>get in touch</b>{" "}
            with us or <b>join our mailing list</b>.
          </p>
        </div>
      </div>
    </div>
  );
}
